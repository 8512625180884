import { useCallback, useEffect, useRef, useState } from 'react'
import isMobile from '../../../../../helpers/isMobile'
import isTablet from '../../../../../helpers/isTablet'
import { useWindowSize } from './windowSize'
import { sleep } from '../../../index'

function useAsyncState<T>(initialState): [T, (value: T) => Promise<void>] {
	const [state, setState] = useState<T>(initialState)
	const resolveState = useRef<((s: T) => T) | null>(null)
	const isMounted = useRef(false)

	useEffect(() => {
		isMounted.current = true

		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (resolveState.current) {
			resolveState.current(state)
		}
	}, [state])

	const setAsyncState = useCallback(
		newState =>
			new Promise(resolve => {
				// console.log('new state', newState, isMounted.current)
				if (isMounted.current) {
					setState(oldState => {
						if (newState === oldState) {
							resolve(newState)
						} else {
							resolveState.current = resolve
						}
						return newState
					})
				}
				// if (newState === state) {
				// 	resolve(newState)
				// } else if (isMounted.current) {
				// 	resolveState.current = resolve
				//
				// }
			}),
		[]
	)

	return [state, setAsyncState]
}
export type SCMedia = {
	isMobile: boolean
	isTablet: boolean
	ifMobile: boolean
}
type ReturnUseMedia = {
	turnToNormal: () => void
	turnToDesktop: () => void
	if_mobile: boolean
	is_mobile: boolean
	is_tablet: boolean
	setIsMobile: (state: boolean) => void
	setIsTablet: (state: boolean) => void
	scMedia: SCMedia
}
export const useMedia = (): ReturnUseMedia => {
	const [is_mobile_val, setIsMobileVal] = useAsyncState<boolean>(isMobile())
	const [is_tablet_val, setIsTabletVal] = useAsyncState<boolean>(isTablet())
	const [is_mobile, setIsMobile] = useState<boolean>(is_mobile_val)
	const [is_tablet, setIsTablet] = useState<boolean>(is_tablet_val)

	useEffect(() => {
		setIsMobile(is_mobile_val)
		setIsTablet(is_tablet_val)
	}, [is_mobile_val, is_tablet_val])

	const if_mobile = is_mobile || is_tablet

	const refreshMedia = async () => {
		await setIsMobileVal(isMobile())
		await setIsTabletVal(isTablet())
	}

	const size = useWindowSize({
		onResize: async () => {
			await refreshMedia()
		},
	})

	const turnToDesktop = async () => {
		await setIsMobileVal(false)
		console.log('setIsMobileVal')
		await setIsTabletVal(true)
		console.log('setIsTabletVal')

		try {
			// let css = '@page { size: landscape; }'
			// let head = document.head || document.getElementsByTagName('head')[0]
			// let style = document.createElement('style')
			//
			// style.type = 'text/css'
			// style.media = 'print'
			// style.id = 'printDesktop'
			//
			// if (style.styleSheet) {
			// 	style.styleSheet.cssText = css
			// } else {
			// 	style.appendChild(document.createTextNode(css))
			// }
			//
			// head.appendChild(style)
		} catch (e) {}

		document.body.classList.add('disable-mobile')
	}

	const turnToNormal = async () => {
		document.body.classList.remove('disable-mobile')

		// await sleep(100)
		await refreshMedia()
		try {
			// document.querySelector('#printDesktop')?.remove()
		} catch (e) {}
	}

	return {
		turnToNormal,
		turnToDesktop,
		if_mobile,
		is_mobile,
		is_tablet,
		setIsMobile,
		setIsTablet,
		scMedia: {
			ifMobile: if_mobile,
			isMobile: is_mobile,
			isTablet: is_tablet,
		},
	}
}
