// Hook
import { useEffect, useState } from 'react'
import { type } from 'os'

type WindowSizeType = {
	width: number | undefined
	height: number | undefined
}
export const useWindowSize = ({
	onResize,
}: {
	onResize?: (sizes?: WindowSizeType) => void
}): WindowSizeType => {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState<WindowSizeType>({
		width: undefined,
		height: undefined,
	})
	useEffect(() => {
		// Handler to call on window resize
		const handleResize = () => {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})

			if (typeof onResize === 'function') {
				onResize({
					width: window.innerWidth,
					height: window.innerHeight,
				})
			}
		}
		// Add event listener
		window.addEventListener('resize', handleResize)
		// Call handler right away so state gets updated with initial window size
		handleResize()
		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, []) // Empty array ensures that effect is only run on mount
	return windowSize
}
