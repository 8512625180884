const isTablet = () => {
	let width = Math.min(window.innerWidth, screen.width)

	const userAgent = navigator.userAgent.toLowerCase()
	const isTabletCheck =
		/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
			userAgent
		)

	return (isTabletCheck || width < 900) && !document.body.classList.contains('disable-mobile')
}
export default isTablet
